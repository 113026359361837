import React, { useState, useEffect } from 'react';
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";
import { config } from '../../config';
import './Generatepage.css';
import shareicon from '../../assets/share_video.svg';
import deleteicon from '../../assets/delete_video.svg';
import ReactPlayer from 'react-player'; 

const Generatepage = ({ result, onBack, setIsLoading, topic_type }) => {
  const [videoUrl, setVideoUrl] = useState('');  // Holds the video URL or object URL for the video Blob
  const [deleteResult, setDeleteResult] = useState('');  // To track delete result (success/failure)
  const [deletingVideoId, setDeletingVideoId] = useState(null);  // To track the video being deleted
  const [showDeletePopup, setShowDeletePopup] = useState(false);  // To control delete popup visibility

  useEffect(() => {
    logEvent(analytics, 'Generation_page_opened', {
      user_action: 'Generate_button Clicked'
    });
  
    if (result) {
      setIsLoading(false);

      // Declare the vidURL variable correctly using let or const
      const vidURL = result.result; // Assuming result.result contains the video URL
      
      // Clean up the URL (remove leading/trailing quotes)
      const cleanedUrl = vidURL.replace(/^"|"$/g, ''); 
      
      // Set the cleaned video URL
      setVideoUrl(cleanedUrl);
      
    }
  }, [result, setIsLoading]);

  const handleDelete = () => {
    setDeletingVideoId(videoUrl);  // Set the video URL being deleted
    setShowDeletePopup(true);  // Show the delete confirmation popup
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(`${config.baseURL}/delete_video.php`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          videoId: deletingVideoId, 
          userId: localStorage.getItem('userId'),
        }), // Send the video URL to backend
      });

      const result = await response.json();

      if (result.success) {
        setDeleteResult('success');
        
        setTimeout(() => {
          setShowDeletePopup(false);
          onBack();       
      }, 3000);  // Show success result in the popup
      } else {
        setDeleteResult('failure'); // Show failure result in the popup
      }
    } catch (error) {
      
      setDeleteResult('failure'); // Show failure result in the popup
    }
  };

  const cancelDelete = () => {
    setShowDeletePopup(false); // Simply close the popup without doing anything
    setDeleteResult(''); // Clear any previous delete result
  };
  const handleShare = async (videoUrl) => {
    try {
      const contentToShare = {
        title: `Watch the video ${videoUrl}`, // Title of the video with topic
        text: `Check out this video: ${videoUrl}`,  // Video URL and topic type combined
        url: videoUrl, // Share the actual URL directly if needed
      };

      if (navigator.share) {
        // Sharing the content
        await navigator.share(contentToShare);
      } else {
        alert("Sharing is not supported in this browser.");
      }
    } catch (error) {
      alert('Sharing failed. Please try again.');
    }
  };

  return (
    <div className="generate-page-container">
      {/* Back button container */}
      <div className="generate-topdiv">
        <div className="backbtntext">
          <button className="back-button" onClick={onBack}>
            <img src={config.Backbutton} alt="Back" />
          </button>
        </div>
      </div>

      {/* Title and buttons container */}
      <div className="title-box_generatepage">
        <span className="title-genearate">
          Faceless Video Generator
        </span>
        <span className="button-container_generatepage">
          <button className="action-button_generatepage" onClick={handleDelete}>
            <img src={deleteicon} alt="" className='btn_img_generatepage' />
            Delete
          </button>
          <button className="action-button_generatepage" onClick={(e) => { e.stopPropagation(); handleShare(videoUrl); }}>
            <img src={shareicon} alt="" className='btn_img_generatepage' />
          </button> 
        </span>
      </div>

      {/* Instruction text */}
      <div className="instruction-text_generatepage">
        For best results make sure you provide detailed instructions.
      </div>

      {/* Video container */}
      <ReactPlayer url={videoUrl} controls  width="90%" style={{borderRadius: '20px',overflow:"hidden"}}  />

      {/* Delete confirmation popup */}
      {showDeletePopup && (
        <div className="delete-popup_generatepage">
          <p>Are you sure you want to delete this video?</p>
          <button onClick={confirmDelete}>Yes, Delete</button>
          <button onClick={cancelDelete}>Cancel</button>
          
          {/* Display result of delete operation */}
          {deleteResult && (
            <div className={`delete-result ${deleteResult}`}>
              {deleteResult === 'success' ? 'Video deleted successfully.' : 'Failed to delete video.'}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Generatepage;
