import React, { useState, useEffect } from 'react';
import deleteicon from '../../assets/delete_video.svg';
import shareicon from '../../assets/share_video.svg';
import './HistoryPreview.css';
import { config } from '../../config';
import ReactPlayer from 'react-player';

const HistoryPreview = ({ result, onBack }) => {
  const [showNotificationcopy, setShowNotificationcopy] = useState(false); // State for showing notification
  const [displayedText, setDisplayedText] = useState('');
  const [topicType, setTopicType] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [deleteResult, setDeleteResult] = useState(''); // To track delete result (success/failure)
  const [deletingVideoId, setDeletingVideoId] = useState(null); // To track the video being deleted
  const [showDeletePopup, setShowDeletePopup] = useState(false); // To control delete popup visibility

  useEffect(() => {
    if (result) {
      // Set the video URL from the passed result (which is just topic_type)
      const cleanedUrl = result.replace(/^"|"$/g, '');  // Removes any leading/trailing quotes
      setVideoUrl(cleanedUrl);
      
    }
  }, [result]);

  const handleCopy = () => {
    const textToCopy = displayedText.trim();  // Clean up any unnecessary leading/trailing spaces

    if (!textToCopy || textToCopy === '') {
     
      alert('No text available to copy.');
      return;
    }

    try {
      // Create a hidden textarea element to copy the text
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;  // Set the value of the textarea to the text you want to copy
      document.body.appendChild(textArea);  // Append the textarea to the body

      // Select the content of the textarea
      textArea.select();
      textArea.setSelectionRange(0, 99999);  // For mobile devices

      // Copy the selected content to clipboard
      document.execCommand('copy');  // This is the standard copy command in browsers

      // Remove the textarea after copying
      document.body.removeChild(textArea);  // Clean up the DOM

      // Show a notification that the text has been copied
      setShowNotificationcopy(true);
      setTimeout(() => setShowNotificationcopy(false), 1000);
    } catch (err) {
      alert('There was an error copying the text. Please try again.');
    }
  };

  const handleShare = async (videoUrl) => {
    try {
      const contentToShare = {
        title: `Watch the video ${videoUrl}`, // Title of the video with topic
        text: `Check out this video: ${videoUrl}`,  // Video URL and topic type combined
        url: videoUrl, // Share the actual URL directly if needed
      };

      if (navigator.share) {
        // Sharing the content
        await navigator.share(contentToShare);
       
      } else {
        alert("Sharing is not supported in this browser.");
      }
    } catch (error) {

      alert('Sharing failed. Please try again.');
    }
  };

  const handleDelete = () => {
    setDeletingVideoId(videoUrl);  // Set the video URL being deleted
    setShowDeletePopup(true);  // Show the delete confirmation popup
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(`${config.baseURL}/delete_video.php`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ videoId: deletingVideoId, userId: localStorage.getItem('userId'), }), // Send the video URL to backend
      });

      const result = await response.json();

      if (result.success) {
        setDeleteResult('success');
        setTimeout(() => {
          setShowDeletePopup(false);
          onBack();       
      }, 3000);  

      } else {
        setDeleteResult('failure'); // Show failure result in the popup
      }

      setTimeout(() => setShowDeletePopup(false), 2000); // Close after 2 seconds
    } catch (error) {
     
      setDeleteResult('failure'); // Show failure result in the popup
      setTimeout(() => setShowDeletePopup(false), 2000); // Close after 2 seconds
    }
  };

  const cancelDelete = () => {
    setShowDeletePopup(false); // Close the popup if the user cancels
    setDeleteResult(''); // Reset delete result
  };

  return (
    <div className="generate-page-container">
      <div className="generate-topdiv">
        <div className="backbtntext">
          <button className="back-button" onClick={onBack}>
            <img src={config.Backbutton} alt="Back" />
          </button>
          <span>Your Video</span>
        </div>
      </div>

      {/* Title and buttons container */}
      <div className="title-box_generatepage">
        <span className="title-genearate">
          Faceless Video Generator
        </span>
        <span className="button-container_generatepage">
          <button className="action-button_generatepage" onClick={handleDelete}>
            <img src={deleteicon} alt="" className="btn_img_generatepage" />
            Delete
          </button>
          <button className="action-button_generatepage" onClick={(e) => { e.stopPropagation(); handleShare(videoUrl); }}>
            <img src={shareicon} alt="" className="btn_img_generatepage" />
          </button>
        </span>
      </div>

      {/* Instruction text */}
      <div className="instruction-text_generatepage">
        For best results make sure you provide detailed instructions.
      </div>

      {/* Video container */}
     
          
        <ReactPlayer url={videoUrl} controls  width="90%" style={{borderRadius: '20px',overflow:"hidden"}}  />
      
  

      {/* Delete confirmation popup */}
      {showDeletePopup && (
        <div className="delete-popup_historypreview">
          <p>Are you sure you want to delete this video?</p>
          <button onClick={confirmDelete}>Delete</button>
          <button onClick={cancelDelete}>Cancel</button>

          {/* Display delete result message */}
          {deleteResult && (
            <div className={`delete-result ${deleteResult}`}>
              {deleteResult === 'success' ? 'Video deleted successfully.' : 'Failed to delete video.'}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HistoryPreview;
