import React, { useState, useEffect } from 'react';
import './Buy.css';
import { IoAlertOutline } from "react-icons/io5";
import { toast } from 'react-toastify';
import { analytics } from './firebase'; 
import { logEvent } from 'firebase/analytics';
import {config} from '../../config'
import GoogleButton from './GoogleAuth';
import { useRef } from 'react';

const Buy = ({ onBack }) => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [plans, setPlans] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showPayPalPopup, setShowPayPalPopup] = useState(false);
    const [modalmessage, setModalmessage] = useState('');
    const [showmodal, setShowmodal] = useState(false);    
    const premiumButtonRef = useRef(null);

    useEffect(() => {
        const fetchPaypalPlans = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${config.baseURL}/get_paypal_plans.php`);
                if (!response.ok) throw new Error('Failed to fetch PayPal plans');
                const data = await response.json();
                const transformedPlans = data.plans_details.map(plan => {
                    const firstBillingCycle = plan.billing_cycles[0];
                    return {
                        id: plan.id,
                        name: plan.name,
                        currency: firstBillingCycle?.pricing_scheme?.fixed_price?.currency_code || 'USD',
                        amount: (parseFloat(plan?.billing_cycles?.[1]?.pricing_scheme?.fixed_price?.value) || 0) * 100,
                        interval: firstBillingCycle?.frequency?.interval_unit === 'WEEK' ? '1' : 
                                  (firstBillingCycle?.frequency?.interval_unit === 'MONTH' ? '2' : '2'),
                        intervalCount: firstBillingCycle?.frequency?.interval_count || 1,
                    };
                });
                setPlans(transformedPlans);
                logEvent(analytics, 'fetch_paypal_plans_FacelessVideo', {
                    plans_count: data.plans_details.length
                });
                checkSubscription();
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPaypalPlans();
        checkActivePlan();
    }, []);

    useEffect(() => {
        logEvent(analytics, 'Premium_page_opend_FacelessVideo', {
            component_name: 'Buy'
        });
    }, []);

    const checkSubscription = async () => {
        try {
            const response = await fetch(`${config.baseURL}/check_subscription.php`, {
                method: 'GET',
                credentials: 'include',
            });
    
            const data = await response.json(); // Parse the response as JSON
    
            if (data.error) {
                
            } else {
                // Handle the subscription data here
                localStorage.setItem('subscriptionStatus', JSON.stringify(data.subscription_status));
                localStorage.setItem('planId', data.plan_id);
                checkActivePlan();// Save plan_id to localStorage
            }
        } catch (error) {
           
        }
    };

    const handleLoginSuccess = (userProfile) => {
        if (userProfile) {
          ;
            setShowModal(false);           
            localStorage.setItem('showComponent', 'true');
            window.location.reload();

           
                logEvent(analytics, 'Login Button on buy premium', {
                    button_name: 'login_button_FacelessVideo',
                });
                
                
                
        } else {
           
            
        }
        
    };

    const handleLoginFailure = (error) => {
      
        if (error.error) {
            alert(`Login failed: ${error.error}`);
        } else {
            
        }
    };
    
        const checkActivePlan = () => {
            const storedPlanId = localStorage.getItem('planId');
            const subscriptionStatus = JSON.parse(localStorage.getItem('subscriptionStatus'));
    
            if (storedPlanId && subscriptionStatus === 'ACTIVE') {
                setPlans((prevPlans) =>
                    prevPlans.map((plan) => {
                        const isActive = plan.id === storedPlanId;
                        return isActive
                            ? { ...plan, active: true }
                            : { ...plan, active: false };
                    })
                    
                );
                
            }
        };
    
        


    const handleSelect = (planId) => {
        setSelectedPlan(planId);
        localStorage.setItem('selectedPlan', planId);
        logEvent(analytics, 'plan_selected_FacelessVideo', {
            plan_id: planId
        });
    };

    const handleSubscribe = async () => {
        // Step 1: Check if a plan is selected
        if (!selectedPlan) {
            setModalmessage('Please select a plan.');
            setShowmodal(true);  // Show modal asking user to select a plan
            return;  // Stop execution if no plan is selected
        }
    
        // Step 2: Get JWT Token from cookies and check if the user is logged in
        const jwtToken = getCookie('auth_token');  // Assuming getCookie function retrieves the token from cookies
        
        if (!jwtToken) {
            setModalMessage('You need to log in to proceed.');
            setShowModal(true);  // Show modal asking user to log in
            return;  // Stop execution if no JWT token is found
        }
    
        try {
            // Send the token in the Authorization header for validating the session
            const response = await fetch(`${config.baseURL}/validate_token.php`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,  // Add the token to the request header
                },
                credentials: 'include',  // Include cookies if needed for session handling
            });
    
            const data = await response.json();
    
            // If the backend returns an error indicating the token is invalid or expired
            if (response.status !== 200 || data.message === 'Invalid token') {
                setModalMessage('Your session has expired. Please log in again.');
                setShowModal(true);  // Show modal if the session is expired or token is invalid
                return;  // Stop execution if the token is invalid or expired
            }
    
            // Step 3: User is logged in and plan is selected, proceed to show the PayPal popup
            logEvent(analytics, 'subscribe_attempt_FacelessVideo', {
                plan_id: selectedPlan,
                actions: "3Day free Trial button clicked",
                user_logged_in: true
            });
    
            // Show the PayPal popup
            setShowPayPalPopup(true);
            document.body.style.overflow = 'hidden';  // Prevent scrolling when popup is open
    
        } catch (error) {
            console.error('Error while checking subscription:', error);
            setModalMessage('An error occurred. Please try again later.');
            setShowModal(true);  // Show modal if there is any error during the subscription check
        }
    };
    const getCookie = (name) => {
        const nameEq = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i].trim();
            if (c.indexOf(nameEq) === 0) return c.substring(nameEq.length, c.length);
        }
        return "";
    };

    const closeModal = () => {
        setShowModal(false);
    };
    
    const closemodal = () => {
        setShowmodal(false);
    };
    
    const closePayPalPopup = () => {
        setShowPayPalPopup(false);
        document.body.style.overflow = '';
        logEvent(analytics, 'paypal_popup_closed_FacelessVideo', {
            user_action: 'cancel'
        });
    };

useEffect(() => {
    if (showPayPalPopup) {
        const loadPaypalSdk = () => {
            const script = document.createElement('script');
            script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
            script.onload = () => {
                logEvent(analytics, 'paypal_btn_loaded_FacelessVideo', {
                    user_action: 'Clicked on Buy Premium'
                });
                renderPayPalButton();
            };
            document.body.appendChild(script);
        };

        const renderPayPalButton = () => {
            const paypalContainer = document.getElementById('paypal-button-container');
            if (paypalContainer) {
                paypalContainer.innerHTML = '';
        
                window.paypal.Buttons({
                    createSubscription: async (data, actions) => {
                        return await actions.subscription.create({
                            'plan_id': selectedPlan,
                        });
                    },
        
                    onApprove: async (data) => {
                     
        
                        // Save subscription info on the backend
                        await fetch(`${config.baseURL}/save_subscription.php`, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                subscriptionId: data.subscriptionID,
                                orderId: data.orderID,
                                planId: selectedPlan,
                                status: 'ACTIVE',
                            }),
                        });
        
                        toast('You have successfully subscribed to the plan! Enjoy your benefits!', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        closePayPalPopup();
                        logEvent(analytics, 'User_subscibed_FacelessVideo', {
                            user_action: 'Clicked on Buy Premium'
                        });
        
                        checkSubscription(); // Make sure this function is defined elsewhere
                    },
        
                    onCancel: (data) => {
                        // Remove the redirect to cancellation page
                        toast('Your subscription was cancelled. You can try again.', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        closePayPalPopup();
                    },
        
                    onError: (err) => {
                        console.error('PayPal Button Error:', err);
                        closePayPalPopup(); // Handle the popup closure on error
                    }
                }).render(paypalContainer);
            }
        };
        
        loadPaypalSdk();
    }
}, [showPayPalPopup, selectedPlan]);


    return (
        <div className="plans-container">
            <div className="left-section">
                <div className='title_buy_premium'>
                    <button className="back-button-buy" ref={premiumButtonRef} onClick={onBack}>
                        <img className="back-button-buy" src={config.Backbutton} alt="Back" />
                    </button>
                          { config.title} <span><img src={config.proicon} alt="" className='proicon-buypage' /></span>
                </div>
                <div className="features">
                    <p className='titlethree'>Get Access To</p>
                    <ul>
                        <li className='pointbuy'><img src={config.righticon} alt="" className='righticon' /> {config.getacceseline1}</li>
                        <li className='pointbuy'><img src={config.righticon} alt="" className='righticon' />{config.getacceseline2}</li>
                        <li className='pointbuy'><img src={config.righticon} alt="" className='righticon' />{config.getacceseline3}</li>
                        <li className='pointbuy'><img src={config.righticon} alt="" className='righticon' />{config.getacceseline4}</li>
                    </ul>
                </div>
            </div>
            {error && <p className="error-location">{error}</p>}
            <div className="plans-wrapper">
                {loading ? (
                    <div className="loading-spinner"></div>
                ) : plans.length > 0 ? (
                    plans.map((plan) => (
                        <div
                            key={plan.id}
                            className={`plan-box-${plan.interval} ${selectedPlan === plan.id || plan.active ? 'selected' : ''} ${plan.active ? 'Current Plan' : ''}`}
                            onClick={() => handleSelect(plan.id)}
                        >
                            <div className={`tag_plan-${plan.interval}-hover`}>
                                {plan.active ? 'Current Plan' : plan.name}
                            </div>
                            <div className='plan_content'>
                                <span className={`tag_plan-${plan.interval}`}>
                                    {plan.active ? 'Current Plan' : plan.name}
                                </span>
                                <p className='plans_text'><span className='discountedprice'>${(((plan.amount + 300) / 100) * (plan.amount === 2999 ? 1.999999 : 1)).toFixed(2)}</span> ${(plan.amount / 100)}  </p>
                                <p className='plans_text'>
                                    <b>{`${plan.currency} ${(plan.amount / 100).toFixed(2)}`}/
                                        {plan.name}</b>
                                </p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className='no-plans'>No plans available</p>
                )}
            </div>
            <div className="price-info">
                
                <button className="buy-button" onClick={handleSubscribe}>
                   
                    3 Days Free Trial
                    <img className="buy-button-img" src={config.Buybutton} alt="" /> 
                    
                </button>
                {selectedPlan && plans.length > 0 && (
                    (() => {
                        const selected = plans.find(plan => plan.id === selectedPlan);
                        if (selected && selected.name) {
                            return (
                                <p>
                                    3 Days Free Trial then ${` ${(selected.amount / 100).toFixed(2)}`} .
                                </p>
                            );
                        }
                        return null;
                    })()
                )}
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-icon">
                            <IoAlertOutline className="modal-img" />
                        </div>
                        <p className="modal-msg">{modalMessage}</p>
                        <GoogleButton id="modal-button" onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} />
                        <button id="modal-button" onClick={closeModal}> Close </button>
                    </div>
                </div>
            )}
            {showmodal && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-icon">
                            <IoAlertOutline className="modal-img" />
                        </div>
                        <p className="modal-msg">{modalmessage}</p>
                        <button id="modal-button" onClick={closemodal}> Close </button>
                    </div>
                </div>
            )}

            {showPayPalPopup && (
                <div className="paypal-popup">
                    <div className="popup-content">
                        <h2>Complete Your Purchase</h2>
                        <div id="paypal-button-container"></div>
                        <button onClick={closePayPalPopup} className="close-button">Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Buy;
